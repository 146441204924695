export function decodeBase64ToObject(str: string) {
  try {
    const binaryString = atob(str);
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    const decodedString = new TextDecoder('utf-8').decode(bytes);

    return JSON.parse(decodedString);
  } catch (error) {
    throw new Error('Invalid Base64 or JSON data.');
  }
}

export function encodeObjectToBase64(object: any) {
  try {
    const str = JSON.stringify(object);
    const encoder = new TextEncoder();
    const bytes = encoder.encode(str);
    const binaryString = bytesToBinaryString(bytes);

    return btoa(binaryString);
  } catch (error) {
    throw new Error('Failed to encode object to Base64.');
  }
}

function bytesToBinaryString(bytes: Uint8Array): string {
  const chunkSize = 0x8000; // 32KB chunk size to prevent call stack overflow
  let binaryString = '';
  for (let i = 0; i < bytes.length; i += chunkSize) {
    const chunk = bytes.subarray(i, i + chunkSize);
    binaryString += String.fromCharCode.apply(null, Array.from(chunk));
  }
  return binaryString;
}
