import * as DashboardActions from './dashboard.actions';
import { StateError } from '..';
import {
  IDashboardImpl,
  IMaintenanceText,
  IPatchDashboardSuccess,
  IWeatherInformation,
  IWidgetImpl,
} from '@app/shared/models/dashboard.model';
import { IAlarmServiceEvents, IMachineServiceEventsResponse, Machine } from '@app/shared/models/machines.model';

export interface State {
  createPending: boolean | null;
  createSuc: IDashboardImpl | undefined;
  deletePending: boolean | null;
  deleteSuc: string | undefined;
  updatePending: boolean | null;
  updateSuc: boolean;
  getPending: boolean | null;
  getSuc: IDashboardImpl | undefined;
  getAllPending: boolean | null;
  getAllSuc: Array<IDashboardImpl> | undefined;
  createWidgetPending: boolean | null;
  createWidgetSuc: IWidgetImpl | undefined;
  deleteWidgetPending: string | null;
  deleteWidgetSuc: string | null;
  updateWidgetPending: boolean | null;
  updateWidgetSuc: IWidgetImpl | null;
  getWidgetPending: boolean | null;
  getWidgetSuc: IWidgetImpl | undefined;
  getAllWidgetPending: boolean | null;
  getAllWidgetSuc: Array<IWidgetImpl> | undefined;
  getWeatherInfoPending: { pending: boolean | null; id: string };
  getWeatherInfoSuc: IWeatherInformation | undefined;
  machinesListSuc: Machine[] | null;
  serviceEventsListPending: boolean;
  serviceEventsListSuc: IMachineServiceEventsResponse | undefined;
  alarmEventsListPending: boolean;
  alarmEventsListSuc: IAlarmServiceEvents[] | undefined;
  machinesListPending: boolean;
  error: StateError;
  type: boolean;
  widgetstore: { fs: boolean; items: string };
  widgetlocs: string | null;
  widgetLocationsPending: boolean | null;
  updateOldPending: boolean | null;
  digitalDeliveryPendingMachines: Machine[] | null;
}
export const initialState: State = {
  createPending: null,
  createSuc: undefined,
  deletePending: null,
  deleteSuc: undefined,
  updatePending: null,
  updateSuc: false,
  getPending: null,
  getSuc: undefined,
  getAllPending: null,
  getAllSuc: undefined,
  createWidgetPending: null,
  createWidgetSuc: undefined,
  deleteWidgetPending: null,
  deleteWidgetSuc: null,
  updateWidgetPending: null,
  updateWidgetSuc: null,
  getWidgetPending: null,
  getWidgetSuc: undefined,
  getAllWidgetPending: null,
  getAllWidgetSuc: undefined,
  getWeatherInfoPending: { pending: null, id: '' },
  getWeatherInfoSuc: undefined,
  machinesListPending: false,
  machinesListSuc: null,
  serviceEventsListPending: false,
  serviceEventsListSuc: undefined,
  alarmEventsListPending: false,
  alarmEventsListSuc: undefined,
  error: null,
  type: true,
  widgetstore: { fs: true, items: '' },
  widgetlocs: '',
  widgetLocationsPending: null,
  updateOldPending: null,
  digitalDeliveryPendingMachines: null,
};

function updateDashboardEntities(stateDashboards: IDashboardImpl[] | undefined, updateDashboard: IPatchDashboardSuccess) {
  const dashboards = stateDashboards ? [...stateDashboards] : [];
  const dashboardIdx = dashboards?.findIndex((db) => db.id === updateDashboard.id);
  if (dashboards?.length > 0 && dashboardIdx >= 0) {
    dashboards[dashboardIdx] = {
      ...dashboards[dashboardIdx],
      ...updateDashboard,
      // widgets: [
      //   ...updateDashboard.widgets
      // ]
    };
  }

  return dashboards;
}

export function reducer(state = initialState, action: DashboardActions.Actions): State {
  switch (action.type) {
    case DashboardActions.UPDATE_OLD: {
      return {
        ...state,
        widgetstore: action.payload,
      };
    }
    case DashboardActions.UPDATE_OLD_SUCCESS: {
      return {
        ...state,
        widgetlocs: action.payload,
        error: null,
      };
    }
    case DashboardActions.UPDATE_OLD_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.UPDATE_OLD_COMPLETE: {
      return {
        ...state,
        updateOldPending: false,
      };
    }
    case DashboardActions.WIDGETLOCATIONS: {
      return {
        ...state,
        type: action.payload,
      };
    }
    case DashboardActions.WIDGETLOCATIONS_SUCCESS: {
      return {
        ...state,
        widgetlocs: action.payload,
        error: null,
      };
    }
    case DashboardActions.WIDGETLOCATIONS_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.WIDGETLOCATIONS_COMPLETE: {
      return {
        ...state,
        widgetLocationsPending: false,
      };
    }
    case DashboardActions.CREATE: {
      return {
        ...state,
        createPending: true,
      };
    }
    case DashboardActions.CREATE_SUCCESS: {
      return {
        ...state,
        createSuc: action.payload,
      };
    }
    case DashboardActions.CREATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.CREATE_COMPLETE: {
      return {
        ...state,
        createPending: false,
      };
    }
    case DashboardActions.DELETE: {
      return {
        ...state,
        deletePending: true,
      };
    }
    case DashboardActions.DELETE_SUCCESS: {
      return {
        ...state,
        deleteSuc: action.payload,
      };
    }
    case DashboardActions.DELETE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.DELETE_COMPLETE: {
      return {
        ...state,
        deletePending: false,
      };
    }
    case DashboardActions.UPDATE: {
      return {
        ...state,
        updatePending: true,
      };
    }
    case DashboardActions.UPDATE_SUCCESS: {
      const updateDashboards = updateDashboardEntities(state.getAllSuc, action.updateDashboardResult);
      return {
        ...state,
        updateSuc: action.payload,
        getAllSuc: updateDashboards,
        error: null,
      };
    }
    case DashboardActions.UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.UPDATE_COMPLETE: {
      return {
        ...state,
        updatePending: false,
      };
    }
    case DashboardActions.GETBYID: {
      return {
        ...state,
        getPending: true,
      };
    }
    case DashboardActions.GETBYID_SUCCESS: {
      return {
        ...state,
        getSuc: action.payload,
      };
    }
    case DashboardActions.GETBYID_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.GETBYID_COMPLETE: {
      return {
        ...state,
        getPending: false,
      };
    }
    case DashboardActions.GETALL: {
      return {
        ...state,
        getAllPending: true,
      };
    }
    case DashboardActions.GETALL_SUCCESS: {
      return {
        ...state,
        getAllSuc: action.payload,
      };
    }
    case DashboardActions.GETALL_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.GETALL_COMPLETE: {
      return {
        ...state,
        getAllPending: false,
      };
    }
    case DashboardActions.CREATE_WIDGET: {
      return {
        ...state,
        createWidgetPending: true,
      };
    }
    case DashboardActions.CREATE_WIDGET_SUCCESS: {
      const dashboards = state.getAllSuc ? [...state.getAllSuc] : [];
      const dashboardIdx = dashboards?.findIndex((db) => db.id === action.dashboardId);
      if (dashboards && dashboardIdx >= 0 && action.payload) {
        dashboards[dashboardIdx] = {
          ...dashboards[dashboardIdx],
          widgets: [...dashboards[dashboardIdx].widgets, action.payload],
        };
      }

      return {
        ...state,
        createWidgetSuc: action.payload,
        getAllSuc: dashboards,
      };
    }
    case DashboardActions.CREATE_WIDGET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.CREATE_WIDGET_COMPLETE: {
      return {
        ...state,
        createWidgetPending: false,
      };
    }
    case DashboardActions.DELETE_WIDGET: {
      return {
        ...state,
        deleteWidgetPending: action.payload.widgetId,
      };
    }
    case DashboardActions.DELETE_WIDGET_SUCCESS: {
      const dashboards = state.getAllSuc ? [...state.getAllSuc] : [];
      const dashboardIdx = dashboards?.findIndex((db) => db.id === action.payload.dashboardId);
      if (dashboards && dashboardIdx >= 0) {
        dashboards[dashboardIdx] = {
          ...dashboards[dashboardIdx],
          widgets: dashboards[dashboardIdx].widgets.filter((w) => w.id !== action.payload.widgetId),
        };
      }

      return {
        ...state,
        deleteWidgetSuc: action.payload.widgetId,
        getAllSuc: dashboards,
      };
    }
    case DashboardActions.DELETE_WIDGET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.DELETE_WIDGET_COMPLETE: {
      return {
        ...state,
        deleteWidgetPending: null,
      };
    }
    case DashboardActions.UPDATE_WIDGET: {
      return {
        ...state,
        updateWidgetPending: true,
      };
    }
    case DashboardActions.UPDATE_WIDGET_SUCCESS: {
      const dashboards = state.getAllSuc ? [...state.getAllSuc] : [];
      const dashboardIdx = dashboards?.findIndex((db) => db.id === action.dashboardId);
      if (dashboards && dashboardIdx >= 0 && action.payload) {
        const udpatedWidgetIdx = dashboards[dashboardIdx].widgets.findIndex((w) => w.id === action.payload.id);

        const before = dashboards[dashboardIdx].widgets.slice(0, udpatedWidgetIdx);
        const after = dashboards[dashboardIdx].widgets.slice(udpatedWidgetIdx + 1);

        dashboards[dashboardIdx] = {
          ...dashboards[dashboardIdx],
          widgets: [...before, action.payload, ...after],
        };
      }

      return {
        ...state,
        updateWidgetSuc: action.payload,
        getAllSuc: dashboards,
      };
    }
    case DashboardActions.UPDATE_WIDGET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.UPDATE_WIDGET_COMPLETE: {
      return {
        ...state,
        updateWidgetPending: false,
      };
    }
    case DashboardActions.GETBYID_WIDGET: {
      return {
        ...state,
        getWidgetPending: true,
      };
    }
    case DashboardActions.GETBYID_WIDGET_SUCCESS: {
      return {
        ...state,
        getWidgetSuc: action.payload,
      };
    }
    case DashboardActions.GETBYID_WIDGET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.GETBYID_WIDGET_COMPLETE: {
      return {
        ...state,
        getWidgetPending: false,
      };
    }
    case DashboardActions.GETALL_WIDGET: {
      return {
        ...state,
        getAllWidgetPending: true,
      };
    }
    case DashboardActions.GETALL_WIDGET_SUCCESS: {
      return {
        ...state,
        getAllWidgetSuc: action.payload,
      };
    }
    case DashboardActions.GETALL_WIDGET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.GETALL_WIDGET_COMPLETE: {
      return {
        ...state,
        getAllWidgetPending: false,
      };
    }
    case DashboardActions.GETWEATHER_INFO: {
      return {
        ...state,
        getWeatherInfoPending: { pending: true, id: action.payload.id },
      };
    }
    case DashboardActions.GETWEATHER_INFO_SUCCESS: {
      return {
        ...state,
        getWeatherInfoSuc: action.payload,
      };
    }
    case DashboardActions.GETWEATHER_INFO_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.GETWEATHER_INFO_COMPLETE: {
      return {
        ...state,
        getWeatherInfoPending: { pending: false, id: action.payload },
      };
    }
    case DashboardActions.MACHINES_LIST: {
      return {
        ...state,
        machinesListPending: true,
      };
    }
    case DashboardActions.MACHINES_LIST_SUCCESS: {
      return {
        ...state,
        machinesListSuc: action.payload,
      };
    }
    case DashboardActions.MACHINES_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.MACHINES_LIST_COMPLETE: {
      return {
        ...state,
        machinesListPending: false,
      };
    }
    case DashboardActions.SERVICEEVENTS_LIST: {
      return {
        ...state,
        serviceEventsListPending: true,
      };
    }
    case DashboardActions.SERVICEEVENTS_LIST_SUCCESS: {
      return {
        ...state,
        serviceEventsListSuc: action.payload,
      };
    }
    case DashboardActions.SERVICEEVENTS_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.SERVICEEVENTS_LIST_COMPLETE: {
      return {
        ...state,
        serviceEventsListPending: false,
      };
    }

    case DashboardActions.ALARMEVENTS_LIST: {
      return {
        ...state,
        alarmEventsListPending: false,
      };
    }
    case DashboardActions.ALARMEVENTS_LIST_SUCCESS: {
      return {
        ...state,
        alarmEventsListSuc: action.payload,
      };
    }
    case DashboardActions.ALARMEVENTS_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case DashboardActions.ALARMEVENTS_LIST_COMPLETE: {
      return {
        ...state,
        alarmEventsListPending: false,
      };
    }

    case DashboardActions.DIGITAL_DELIVERY_PENDING_MACHINES_LIST: {
      return {
        ...state,
        machinesListPending: true,
      };
    }

    case DashboardActions.DIGITAL_DELIVERY_PENDING_MACHINES_LIST_SUCCESS: {
      return {
        ...state,
        digitalDeliveryPendingMachines: action.payload,
      };
    }

    case DashboardActions.DIGITAL_DELIVERY_PENDING_MACHINES_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case DashboardActions.DIGITAL_DELIVERY_PENDING_MACHINES_LIST_COMPLETE: {
      return {
        ...state,
        machinesListPending: false,
      };
    }

    case DashboardActions.RESET_DASHBOARD: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCreatePending = (state: State) => state.createPending;
export const getDeletePending = (state: State) => state.deletePending;
export const getUpdatePending = (state: State) => state.updatePending;
export const getGetByIdPending = (state: State) => state.getPending;
export const getGetAllPending = (state: State) => state.getAllPending;
export const getCreateWidgetPending = (state: State) => state.createWidgetPending;
export const getDeleteWidgetPending = (state: State) => state.deleteWidgetPending;
export const getUpdateWidgetPending = (state: State) => state.updateWidgetPending;
export const getGetWidgetByIdPending = (state: State) => state.getWidgetPending;
export const getGetAllWidgetPending = (state: State) => state.getAllWidgetPending;
export const getWeatherInfoPending = (state: State) => state.getWeatherInfoPending;
export const getAllDashboards = (state: State) => state.getAllSuc;
export const getDashboardById = (state: State) => state.getSuc;
export const getNewDashboard = (state: State) => state.createSuc;
export const getAllWidgets = (state: State) => state.getAllWidgetSuc;
export const getWidgetById = (state: State) => state.getWidgetSuc;
export const getNewWidget = (state: State) => state.createWidgetSuc;
export const getWeatherInfo = (state: State) => state.getWeatherInfoSuc;
export const getMachinesList = (state: State) => state.machinesListSuc;
export const getMachinesListPending = (state: State) => state.machinesListPending;
export const getServiceEventsList = (state: State) => state.serviceEventsListSuc;
export const getServiceEventsListPending = (state: State) => state.serviceEventsListPending;
export const getAlarmeEventsList = (state: State) => state.alarmEventsListSuc;
export const getAlarmEventsListPending = (state: State) => state.alarmEventsListPending;

export const getDashboardItems = (state: State) => state.widgetlocs;
export const getLastDeletedWidget = (state: State) => state.deleteWidgetSuc;

export const getError = (state: State) => state.error;
export const getLastUpdatedWidget = (state: State) => state.updateWidgetSuc;
export const getDigitalDeliveryPendingMachines = (state: State) => state.digitalDeliveryPendingMachines;
