export interface Dictionary<T = any>{[key: string]: T};

export function parseWidgetConfiguration<T extends Dictionary>(configString: string): T | null {
  const keyValuePairs = configString.split(';');
  if (!keyValuePairs) {
    return null;
  }

  const result: T = keyValuePairs.reduce(
    (prev, currentKeyValuePair) => {
      if (!currentKeyValuePair) {
        return prev;
      }

      const keyValueSplit = currentKeyValuePair.split('=');
      if (!keyValueSplit || keyValueSplit.length < 2) {
        return prev;
      }
      (prev as Dictionary)[keyValueSplit[0]] = keyValueSplit[1];
      return prev;
    }, {} as T
  );

  return result;
}

export function writeWidgetConfiguration<T extends Dictionary>(config: T): string {
  let result = '';
  // skip undefined values
  const entries = Object.entries(config).filter(([key, value]) => value !== undefined);
  entries.forEach(([key, value], idx) => {
    result += `${key}=${value}`;

    if (idx < entries.length - 1) {
      result += ';';
    }
  });

  return result;
}
